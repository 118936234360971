import { createBrowserRouter } from "react-router-dom";
import Home from "../components/pages/Home";
import Error404 from "../components/pages/Error404";
import Products from "../components/pages/Products/Products";
import TablesView from "../components/pages/TablesView";
import ProductsForm from "../components/pages/Products/ProductsForm";
import Companies from "../components/pages/Companies/Companies";
import Locations from "../components/pages/Locations/Locations";
import App from "../components/App";
import OutsideAppLogin from "../components/OutsideAppLogin";
import OutsideAppRegister from "../components/OutsideAppRegister";
import OutsideAppPassword from "../components/OutsideAppPassword";
import OutsideAppTransactionView from "../components/OutsideAppTransactionView";
import OutsideAppForgotPassword from "../components/OutsideAppForgotPassword";
import LocationsForms from "../components/pages/Locations/LocationsForm";
import LocationsView from "../components/pages/Locations/LocationsView";
import CompaniesView from "../components/pages/Companies/CompaniesView";
import CompaniesForms from "../components/pages/Companies/CompaniesForm";
import ProductsView from "../components/pages/Products/ProductsView";
import Categories from "../components/pages/Categories/Categories";
import CategoriesView from "../components/pages/Categories/CategoriesView";
import CategoriesForms from "../components/pages/Categories/CategoriesForms";
import Subcategories from "../components/pages/Subcategories/Subcategories";
import SubcategoriesForms from "../components/pages/Subcategories/SubcategoriesForm";
import SubcategoriesView from "../components/pages/Subcategories/SubcategoriesView";
import Items from "../components/pages/Items/Items";
import ItemsView from "../components/pages/Items/ItemsView";
import DessertsConfiguration from "../components/pages/DessertsConfiguration/DessertsConfiguration";
import Orders from "../components/pages/Orders/Orders";
import OrdersView from "../components/pages/Orders/OrdersView";
import UsersLocations from "../components/pages/UsersLocations/UsersLocations";
import UsersLocationsView from "../components/pages/UsersLocations/UsersLocationsView";
import UsersLocationsForms from "../components/pages/UsersLocations/UsersLocationsForm";
import Users from "../components/pages/Users/Users";
import UsersView from "../components/pages/Users/UsersView";
import UsersForms from "../components/pages/Users/UsersForm";
import Transactions from "../components/pages/Transactions/Transactions";
import TransactionsView from "../components/pages/Transactions/TransactionsView";
import UsersCompanies from "../components/pages/UsersCompanies/UsersCompanies";
import UsersCompaniesView from "../components/pages/UsersCompanies/UsersCompaniesView";
import UsersCompaniesForm from "../components/pages/UsersCompanies/UsersCompaniesForm";
import ProductLocation2 from "../components/pages/ProductsLocation2/ProductLocation2";
import ProductLocation2View from "../components/pages/ProductsLocation2/ProductLocation2View";
import ProductLocation2Form from "../components/pages/ProductsLocation2/ProductLocation2Form";
import ItemForm from "../components/pages/Items/ItemsForms";
import UserProfileForm from "../components/pages/UsersProfile/UserProfileForm";
import UserProfileView from "../components/pages/UsersProfile/UserProfileView";
import TaxesGroup from "../components/pages/TaxesGroup/TaxesGroup";
import TaxesGroupForm from "../components/pages/TaxesGroup/TaxesGroupForm";
import TaxesGroupView from "../components/pages/TaxesGroup/TaxesGroupView";
import ProductsPizzaConfiguration from "../components/pages/ProductsPizzaConfiguration/ProductsPizzaConfiguration";
import ProductsPizzaConfigurationForm from "../components/pages/ProductsPizzaConfiguration/ProductsPizzaConfigurationForm";
import ProductsPizzaConfigurationView from "../components/pages/ProductsPizzaConfiguration/ProductsPizzaConfigurationView";
import Printers from "../components/pages/Printers/Printers";
import PrintersForm from "../components/pages/Printers/PrintersForm";
import PrintersView from "../components/pages/Printers/PrintersView";
import PaymentProcessors from "../components/pages/PaymentProcessors/PaymentProcessors";
import PaymentProcessorsForm from "../components/pages/PaymentProcessors/PaymentProcessorsForm";
import PaymentProcessorsView from "../components/pages/PaymentProcessors/PaymentProcessorsView";
import Devices from "../components/pages/Devices/Devices";
import DevicesForm from "../components/pages/Devices/DevicesForm";
import DevicesView from "../components/pages/Devices/DevicesView";
import Pos from "../components/pages/Pos/Pos";
import PosForm from "../components/pages/Pos/PosForm";
import PosView from "../components/pages/Pos/PosView";
import Closeout from "../components/pages/Closeout/Closeout";
import Plans from "../components/pages/Plans/Plans";
import PlansForm from "../components/pages/Plans/PlansForm";
import PlansView from "../components/pages/Plans/PlansView";
import Coupons from "../components/pages/Coupons/Coupons";
import CouponsForm from "../components/pages/Coupons/CouponsForm";
import CouponsView from "../components/pages/Coupons/CouponsView";
import CloseCashier from "../components/pages/CloseCashier/CloseCashier";
import AlarmsConfiguration from "../components/pages/AlarmsConfiguration/AlarmsConfiguration";
import AlarmsConfigurationView from "../components/pages/AlarmsConfiguration/AlarmsConfigurationView";
import AlarmsConfigurationForm from "../components/pages/AlarmsConfiguration/AlarmsConfigurationForm";
import SubscriptionLocation from "../components/pages/SubscriptionLocation/SubscriptionLocation";
import SubscriptionLocationView from "../components/pages/SubscriptionLocation/SubscriptionLocationView";
import SubscriptionPOS from "../components/pages/SubscriptionPOS/SubscriptionPOS";
import SubscriptionPOSView from "../components/pages/SubscriptionPOS/SubscriptionPOSView";
import TransactionsCustomer from "../components/pages/TransactionsCustomer/TransactionsCustomer";
import TransactionsCustomerView from "../components/pages/TransactionsCustomer/TransactionsCustomerView";
import CloseCashierHistory from "../components/pages/CloseCashier/CloseCashierHistory";
import TransactionsCustomerMonth from "../components/pages/TransactionsCustomer/TransactionsCustomerMonth";
import ReportSuscription from "../components/pages/ReportSuscription/ReportSuscription";
import ReportSales from "../components/pages/ReportSales/ReportSales";


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <Error404 />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/tables",
                element: <TablesView />
            },
            {
                path: "/close/orders",
                element: <CloseCashier />
            },
            {
                path: "/close/cashier",
                element: <CloseCashierHistory />
            },
            {
                path: "/close/cashier/view/:id",
                element: <CloseCashier />
            },
            {
                path: "configuration/profile/:id",
                element: <UserProfileView />
            },
            {
                path: "configuration/profile/:id/edit",
                element: <UserProfileForm />
            },
            {
                path: "administration/products",
                element: <Products />
            },
            {
                path: "administration/products/view/:id",
                element: <ProductsView />
            },
            {
                path: "administration/products/create",
                element: <ProductsForm />
            },
            {
                path: "administration/products/edit/:id",
                element: <ProductsForm />
            },
            // Companies
            {
                path: "administration/companies",
                element: <Companies />
            },
            {
                path: "administration/companies/view/:id",
                element: <CompaniesView />
            },
            {
                path: "administration/companies/create",
                element: <CompaniesForms />
            },
            {
                path: "administration/companies/edit/:id",
                element: <CompaniesForms />
            },
            // Locations
            {
                path: "administration/locations",
                element: <Locations />
            },
            {
                path: "administration/locations/create",
                element: <LocationsForms />
            },
            {
                path: "administration/locations/view/:id",
                element: <LocationsView />
            },
            {
                path: "administration/locations/edit/:id",
                element: <LocationsForms />
            },
            // categories
            {
                path: "administration/categories",
                element: <Categories />
            },
            {
                path: "administration/categories/view/:id",
                element: <CategoriesView />
            },
            {
                path: "administration/categories/create",
                element: <CategoriesForms />
            },
            {
                path: "administration/categories/edit/:id",
                element: <CategoriesForms />
            },
            // subcategories
            {
                path: "administration/subcategories",
                element: <Subcategories />
            },
            {
                path: "administration/subcategories/view/:id",
                element: <SubcategoriesView />
            },
            {
                path: "administration/subcategories/create",
                element: <SubcategoriesForms />
            },
            {
                path: "administration/subcategories/edit/:id",
                element: <SubcategoriesForms />
            },
            // items
            {
                path: "configuration/items",
                element: <Items />
            },
            {
                path: "configuration/items/view/:id",
                element: <ItemsView />
            },
            {
                path: "configuration/items/create",
                element: <ItemForm/>
            },
            {
                path: "configuration/items/edit/:id",
                element: <ItemForm/>
            },
            //Products location 2 - PAULA
            {
                path: "configuration/locations/products",
                element: <ProductLocation2 />
            },
            {
                path: "configuration/locations/products/view/:id",
                element: <ProductLocation2View />
            },
            {
                path: "configuration/locations/products/create",
                element: <ProductLocation2Form />
            },
            {
                path: "configuration/locations/products/edit/:id",
                element: <ProductLocation2Form />
            },
            // desserts configuration
            {
                path: "configuration/products/desserts",
                element: <DessertsConfiguration/>
            },
            // close-out configuration
            {
                path: "configuration/closeout",
                element: <Closeout/>
            },
            // coupons configuration
            {
                path: "configuration/coupons",
                element: <Coupons/>
            },
            {
                path: "configuration/coupons/create",
                element: <CouponsForm/>
            },
            {
                path: "configuration/coupons/edit/:id",
                element: <CouponsForm/>
            },
            {
                path: "configuration/coupons/view/:id",
                element: <CouponsView/>
            },
            // alarms configuration
            {
                path: "orders/alarmsConfiguration",
                element: <AlarmsConfiguration/>
            },
            {
                path: "orders/alarmsConfiguration/create",
                element: <AlarmsConfigurationForm/>
            },
            {
                path: "orders/alarmsConfiguration/edit/:id",
                element: <AlarmsConfigurationForm/>
            },
            {
                path: "orders/alarmsConfiguration/view/:id",
                element: <AlarmsConfigurationView/>
            },
            // products pizza configuration
            {
                path: "configuration/pizzaconfiguration",
                element: <ProductsPizzaConfiguration />
            },
            {
                path: "configuration/pizzaconfiguration/create",
                element: <ProductsPizzaConfigurationForm />
            },
            {
                path: "configuration/pizzaconfiguration/edit/:id",
                element: <ProductsPizzaConfigurationForm />
            },
            {
                path: "configuration/pizzaconfiguration/view/:id",
                element: <ProductsPizzaConfigurationView />
            },
            //orders
            {
                path: "orders",
                element: <Orders/>
            },
            {
                path: "orders/view/:id",
                element: <OrdersView/>
            },
             //Users Locations
            {
                path: "administration/users/locations",
                element: <UsersLocations/>
            },
            {
                path: "administration/users/locations/view/:id",
                element: <UsersLocationsView/>
            },
            {
                path: "administration/users/locations/create",
                element: <UsersLocationsForms />
            },
            {
                path: "administration/users/locations/edit/:id",
                element: <UsersLocationsForms />
            },
            //Users
            {
                path: "administration/users",
                element: <Users/>
            },
            {
                path: "administration/users/view/:id",
                element: <UsersView/>
            },
            {
                path: "administration/users/create",
                element: <UsersForms />
            },
            {
                path: "administration/users/edit/:id",
                element: <UsersForms />
            },

            //transactions
            {
                path: "transactions",
                element: <Transactions />
            },
            {
                path: "transactions/view/:id",
                element: <TransactionsView />
            },
            //usersCompanies
            {
                path: "configuration/userscompanies",
                element: <UsersCompanies />
            },
            {
                path: "configuration/userscompanies/view/:id",
                element: <UsersCompaniesView />
            },
            {
                path: "configuration/userscompanies/create",
                element: <UsersCompaniesForm />
            },
            {
                path: "configuration/userscompanies/edit/:id",
                element: <UsersCompaniesForm />
            },
             //subscription_location
             {
                path: "configuration/subscription_location",
                element: <SubscriptionLocation />
            },
            {
                path: "configuration/subscription_location/view/:id",
                element: <SubscriptionLocationView />
            },
            //subscription_pos
            {
                path: "configuration/subscription_pos",
                element: <SubscriptionPOS />
            },
            {
                path: "configuration/subscription_pos/view/:id",
                element: <SubscriptionPOSView />
            },
            //taxes
            {
                path: "web/configuration/taxes",
                element: <TaxesGroup />
            },
            {
                path: "web/configuration/taxes/view/:id",
                element: <TaxesGroupView />
            },
            {
                path: "web/configuration/taxes/create",
                element: <TaxesGroupForm />
            },
            {
                path: "web/configuration/taxes/edit/:id",
                element: <TaxesGroupForm />
            },
            //configuration printers
            {
                path: "configuration/printers",
                element: <Printers />
            },
            {
                path: "configuration/printers/create",
                element: <PrintersForm />
            },
            {
                path: "configuration/printers/edit/:id",
                element: <PrintersForm />
            },
            {
                path: "configuration/printers/view/:id",
                element: <PrintersView />
            },
            //configuration printers
            {
                path: "configuration/payment-processor",
                element: <PaymentProcessors />
            },
            {
                path: "configuration/payment-processor/create",
                element: <PaymentProcessorsForm />
            },
            {
                path: "configuration/payment-processor/edit/:id",
                element: <PaymentProcessorsForm />
            },
            {
                path: "configuration/payment-processor/view/:id",
                element: <PaymentProcessorsView />
            },
            {
                path: "administration/devices",
                element: <Devices />
            },
            {
                path: "administration/devices/view/:id",
                element: <DevicesView/>
            },
            {
                path: "administration/devices/create",
                element: <DevicesForm />
            },
            {
                path: "administration/devices/edit/:id",
                element: <DevicesForm />
            },
            {
                path: "configuration/pos",
                element: <Pos />
            },
            {
                path: "configuration/pos/view/:id",
                element: <PosView/>
            },
            {
                path: "configuration/pos/create",
                element: <PosForm />
            },
            {
                path: "configuration/pos/edit/:id",
                element: <PosForm />
            },
            // Plans
            {
                path: "plans",
                element: <Plans />
            },
            {
                path: "plans/create",
                element: <PlansForm />
            },
            {
                path: "plans/view/:id",
                element: <PlansView />
            },
            {
                path: "plans/edit/:id",
                element: <PlansForm />
            },
            // Customers Transactions
            {
                path: "transactions-customer",
                element: <TransactionsCustomer />
            },
            {
                path: "transactions-customer/view/:id",
                element: <TransactionsCustomerView />
            },
            {
                path: "transactions-customer-month",
                element: <TransactionsCustomerMonth />
            },
            // Reports - Suscriptions 
            {
                path: "reports/subscriptions",
                element: <ReportSuscription />
            },
            {
                path: "reports/sales",
                element: <ReportSales />
            },
        ]
    },
    {
        path: "/login",
        element: <OutsideAppLogin />,
        errorElement: <Error404 />
    },
    {
        path: "/register",
        element: <OutsideAppRegister />,
        errorElement: <Error404 />
    },
    {
        path: "/register/80269108-62bb-4a2c-a00a-a89f3889970d", //Free Plan
        element: <OutsideAppRegister />,
        errorElement: <Error404 />
    },
    {
        path: "/register/2cd07859-869e-4869-8603-7822a46d4d94", // Basic Plan
        element: <OutsideAppRegister />,
        errorElement: <Error404 />
    },
    {
        path: "/forgot",
        element: <OutsideAppForgotPassword />,
        errorElement: <Error404 />
    },
    {
        path: "/set_main_password/company/:identificator",
        element: <OutsideAppPassword />,
        errorElement: <Error404 />
    },
    {
        path: "/check_invoice/:id",
        element: <OutsideAppTransactionView />,
        errorElement: <Error404 />
    }
])

export default router;