import { useTranslation } from "react-i18next";
import { useFetch } from '../../../hooks/useFecth';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';

import DataTable from "react-data-table-component";
import HandlerApp from '../../../utils/handlerApp';
import NoDataTables from "../../noDataTable/NoDataTables";

export default function ReportSuscription() {

    const api = useFetch();
    const handlerApp = HandlerApp();
    const [t] = useTranslation("global");

    const endpoint = "reports/subscriptions";

    const { modeTheme } = useContext(AppContext);

    const [data, setData] = useState();

    useEffect(() => {
        sendRequest()
    }, []);

    const columns = [
        {
            id: 'mes',
            name: t('reportSubscriptionComponent.monthTitle'),
            selector: row => row.mes,
            sortable: true,
        },
        {
            id: 'cant_suscripciones_pos',
            name: t('reportSubscriptionComponent.posTitle'),
            selector: row => row.cant_suscripciones_pos,
            sortable: true,
        },
        {
            id: 'cant_suscripciones_sucursales',
            name: t('reportSubscriptionComponent.locationTitle'),
            selector: row => row.cant_suscripciones_sucursales,
            sortable: true,
        },
        {
            id: 'ingresos_suscripciones_pos',
            name: t('reportSubscriptionComponent.totalPosTitle'),
            selector: row => row.ingresos_suscripciones_pos,
            sortable: true,
        },
        {
            id: 'ingresos_suscripciones_sucursales',
            name: t('reportSubscriptionComponent.totalLocationTitle'),
            selector: row => row.ingresos_suscripciones_sucursales,
            sortable: true,
        },
        {
            id: 'ingresos_totales',
            name: t('reportSubscriptionComponent.totalTitle'),
            selector: row => row.ingresos_totales,
            sortable: true,
        },
    ];

    const sendRequest = async () => {
        handlerApp.setLoader(true);
        try {
            const response = await api.get(endpoint);
            if (response.success) {
                let dataResponse = response.data;
                dataResponse = dataResponse?.map(e => {
                    e.ingresos_suscripciones_pos = handlerApp.formatCurrency(e.ingresos_suscripciones_pos);
                    e.ingresos_suscripciones_sucursales = handlerApp.formatCurrency(e.ingresos_suscripciones_sucursales);
                    e.ingresos_totales = handlerApp.formatCurrency(e.ingresos_totales);
                    return e;
                })
                setData(dataResponse)
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        } catch (error) {
            handlerApp.showError();
            handlerApp.setLoader(false);
        }
    }

    const handlerAction = () => {
        sendRequest();
    }

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => {
            return (
                item.mes?.toLowerCase().includes(filterText.toLowerCase()) ||
                item.cant_suscripciones_pos?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
                item.cant_suscripciones_sucursales?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
                item.ingresos_suscripciones_pos?.toLowerCase().includes(filterText.toLowerCase()) ||
                item.ingresos_suscripciones_sucursales?.toLowerCase().includes(filterText.toLowerCase()) ||
                item.ingresos_totales?.toLowerCase().includes(filterText.toLowerCase())
            )
        }
    );

    return (
        <div className="app container">
            <div className="title-section-forms">
                <h3> {t("reportSubscriptionComponent.subscriptions")}</h3>
                <p> {t("reportSubscriptionComponent.subscriptionsDescription")}</p>
            </div>
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t('search')}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            <div className="action-buttons">
                                <button className="btn-table-actions" onClick={() => { handlerAction() }}>
                                    <span className="material-symbols-outlined">autorenew</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeader
                        noDataComponent={<NoDataTables />}
                        theme={modeTheme ? "solarized" : ""}
                    />
                </div>
            </div>
        </div>
    );
}