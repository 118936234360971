import { useTranslation } from "react-i18next";
import lightSelectLocation from '../../assets/img/selectLocation-Ligth.png';
import darkSelectLocation from '../../assets/img/selectLocation-Dark.png';
import { AppContext } from "../../context/AppContext";
import { useContext } from "react";

const SelectLocation = ({back})=>{
    const [t]         = useTranslation("global");
    const profile     = localStorage.getItem("profile");
    const {modeTheme} = useContext(AppContext);

    return(
        profile < 3 ?
            <div className="app container">
                <div className="d-block text-center mb-4">
                    <h3 className="select-location-text">{t('companyAndLocationSelect')}</h3>
                </div>
                <div className="container bold-container-content">
                    <span className="d-block text-center text-gray light">{t('locationAndCompanySelectExplain')}.</span>
                    <div className="d-flex justify-content-center aling-items-center m-4">
                        {modeTheme ?
                            <img src={darkSelectLocation} alt="SelectLocationLight"/>
                            :
                            <img src={lightSelectLocation} alt="SelectLocationDark"/>
                        }
                    </div>
                </div>
            </div>
        :
            <div className="app container">
                <div className="d-block text-center mb-4">
                    <h3 className="select-location-text">{t('locationSelect')}</h3>
                </div>
                <div className="container bold-container-content">
                    <span className="d-block text-center text-gray light">{t('locationSelectExplain')}.</span>
                    <div className="d-flex justify-content-center aling-items-center m-4">
                    {modeTheme ?
                            <img src={darkSelectLocation} alt="SelectLocationLight"/>
                            :
                            <img src={lightSelectLocation} alt="SelectLocationDark"/>
                        }
                    </div>
                </div>
            </div>
    )
}

export default SelectLocation;