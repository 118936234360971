import "./templates/App.css";
import "./templates/AppDark.css";
import "./templates/variables.css";
import "./templates/BoldFramework.css";
import "./templates/BoldFrameworkDark.css";
import Aside from "./organisms/Aside";
import Footer from "./organisms/Footer";
import Loader from "./templates/Loader";
import HandlerApp from "../utils/handlerApp";
import MainHeader from "./organisms/MainHeader";
import { Outlet } from "react-router";
import { db } from "../firebase/config";
import { Logout } from "../utils/BoldLogin";
import { useFetch } from "../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { AppContext } from "../context/AppContext";
import { LoaderContext } from "../context/LoaderContext";
import { LocationContext } from "../context/LocationContext";
import { useContext, useEffect, useRef, useState } from "react";
import { collection, query, onSnapshot, where } from "firebase/firestore";


const FirstDashboard = () => {  
    const [t]           = useTranslation('global');
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const logout        = Logout();

    // Menu
    const [resultMenu, setResultMenu] = useState();
    const [menu, setMenu] = useState([]);
    const [loaderMenu,setLoaderMenu] = useState(true);
    const url = window.location.pathname.substring(1);

    // Responsive
    const [mobile,setMobile]        = useState(false);
    const [collapse,setCollapse]    = useState(false);
    const [run,setRun]              = useState(false);
    const [show,setShow]            = useState(false);
    const contentAside              = useRef();
    const globalContentAside        = useRef();
    const contentGlobalApp          = useRef();
    const menuRef                   = useRef([]);
    const profile                   = localStorage.getItem("profile");
    const [stepIndex, setStepIndex] = useState(0);

    // Locations
    const {loader,setLoaderInner}           = useContext(LoaderContext);
    const {app,setApp}                      = useContext(AppContext);
    const {location,setLocation, locations, setLocations, checkLocations, companies, getCompanies, setCompany,setLabelLocation,setLabelCompany, locationLabel } = useContext(LocationContext);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            if (profile < 3) {
                getCompanies();
            }
            checkLocations();
        }
    }, []);

    useEffect(()=>{
        setLabelLocation(localStorage.getItem("currentLocation"));
    },[locations])

    useEffect(()=>{
        setLabelLocation(localStorage.getItem("currentCompany"));
    },[companies])

    const handleLocation = (id)=>{
        setLocation(id);
        localStorage.setItem('currentLocation',id);
        setLabelLocation(id);
    }

    const handleCompany = (id)=>{
        setLoaderInner(true)
        setLocations([]);
        localStorage.setItem("locations", JSON.stringify([]));
        setLabelCompany(t('globalCompany'));
        setCompany("");
        setLocation('')
        localStorage.setItem('currentCompany', "");
        localStorage.setItem('currentLocation', "");
        if(id){
            setCompany(id);
            setLabelCompany(id);
            localStorage.setItem('currentCompany',id);
        }else{
            setLoaderInner(false);
        }
    }


    useEffect(()=>{
        if (localStorage.getItem("token")) {
            getMenu();
            getUserTuto()
            let locationList = JSON.parse(localStorage.getItem("locations"));
            if(localStorage.getItem("currentLocation") !== null && localStorage.getItem("currentLocation") !== undefined && localStorage.getItem("currentLocation") !== ""){
                setLocation(localStorage.getItem("currentLocation"));
            }
            setLocations(locationList);

            // Responsive
            let isMobile = (window.innerWidth < 780) ? true: false;
            setMobile(isMobile);
            if(!isMobile){
                setShow(true);
            }
            window.addEventListener('resize',()=>{
                let isMobile = (window.innerWidth < 780) ? true: false;
                setMobile(isMobile);
            })
        }
    },[])

    // Menu - Aside
    const getMenu = ()=>{
        api.post("/menu")
        .then(response => {
            setResultMenu(response.data)
            setLoaderMenu(false);
        })
        .catch(error => {
            setLoaderMenu(false);
        });
    }

    const getUserTuto = ()=>{
        api.get("/administration/users", {
            action: "getUserTutoInfo"
        })
        .then(response => {
            if(response.success){
                if(response.data[0].user_tutorial == 0){
                    setRun(true)
                    setLoaderMenu(false);
                    handlerApp.setLoader(false)
                }else{
                    setRun(false)
                    setLoaderMenu(false);
                }
            }else{
                setLoaderMenu(false);
            }
        })
        .catch(error => {
            setLoaderMenu(false);
        });
    }

    const initTuto = () => {
        setStepIndex(0)
        setCollapse(true)
        setRun(true)
    }

    useEffect(() => {
        const username = localStorage.getItem("username");
        const unsubscribe = onSnapshot(
            query(collection(db, 'token_users'),
                where("user_email", "==", username),
            ),
            (snapshot) => {
                const sessions = snapshot.docs.map((doc) => doc.data());
                if(sessions.length == 0){
                    logout.closeSession();
                }
            }
        );
        return () => {
            unsubscribe();
        };
    }, [url]);

    useEffect(() => {

        let aux = "";
        let parentMenus = [];
        let permissions = [];

        resultMenu?.forEach(title => {
            if (title.menu_parent !== aux && title.menu_parent !== '') {
                aux = title.menu_parent;
                let childrens = [];
                resultMenu.forEach(song => {
                    if (song.menu_parent === aux && song.menu_children !== '') {
                        childrens.push(song)
                        let perm = song.permissions;
                        perm = perm.split('|');
                        permissions[song.menu_children_action] = perm;
                        title.childrens = childrens;

                    }
                })
                parentMenus.push(title);
            }
        })
        setMenu(parentMenus);

        // Actualizacion de contexto
        setApp({...app,permissions, location});

    }, [resultMenu])

    // ---------------------- Responsive
    useEffect(()=>{
        if(!mobile){
            setShow(true)
        }
    },[mobile])

    useEffect(()=>{
        if(show){
            contentAside.current.className="aside show";
            contentGlobalApp.current.className="bold-container";
            if(mobile){
                globalContentAside.current.className="shadow-aside";
            }
        }else{
            if(mobile){
                contentAside.current.className="aside hide";
            }else{
                contentGlobalApp.current.className="bold-container full";
                contentAside.current.className = "aside hide";
            }
            globalContentAside.current.className="";
        }
    },[show])

    const toggleAside = ()=>{
        if(!mobile){
            contentGlobalApp.current.className="bold-container full";
            contentAside.current.className = "aside hide";
        }
        setShow(!show)
    }
    // ---------------------- /Responsive
    const showAside = ()=>{
        setShow(true)
    }
    
    return (
        <>

            <div className="bold-application">
                <div ref={globalContentAside} className="global-content-aside">
                    <div className="menu-btn-toggle" role="button" onClick={toggleAside}>
                        &times;
                    </div>
                    <div ref={contentAside} className={ mobile ? 'aside hide': 'aside'}>
                        <Aside 
                            loaderMenu={loaderMenu} 
                            menu={menu} run={run} 
                            setRun={setRun} 
                            menuRef={menuRef} 
                            collapse={collapse} 
                            setCollapse={setCollapse} 
                            setStepIndex={setStepIndex} 
                            stepIndex={stepIndex} 
                            setShow={showAside} />
                    </div>
                </div>
                <div ref={contentGlobalApp}className="bold-container">
                    
                        <MainHeader 
                            toggleAside={toggleAside} 
                            run={initTuto} 
                            handleLocation={handleLocation} 
                            handleCompany={handleCompany} 
                            locationLabel={locationLabel} 
                        />
                        { loader && <Loader/>}
                        
                        <Outlet />
                        <Footer />
                </div>
            </div>
        </>
    )
}
export default FirstDashboard;