
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import DataTable from "react-data-table-component";
import NoDataTables from "../../noDataTable/NoDataTables";
import imageNotFound from "../../../assets/img/image-not-found.webp";
import Visa from "../../../assets/img/Visa_Inc._logo.svg";
import Amex from "../../../assets/img/american-express.png";
import Diners from "../../../assets/img/diners_club.png";
import MC from "../../../assets/img/MasterCard_Logo.svg";
import { InputAdornment, TextField } from "@mui/material";
import InputMask from 'react-input-mask';
import { Button } from "react-bootstrap";
import moment from "moment";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import Modal from 'react-modal';
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { BadgeSharp } from "@mui/icons-material";

Modal.setAppElement('#root');


const UserProfileView = () => {

    const endpoint = "subscription-location";
    const endpointCardDefault = "configuration/card-default";
    const endpointPos = "subscription-pos";
    const endpointUser = "configuration/profile";
    const endpointSub = "configuration/get_subscription_location";
    const endpointSubPos = "configuration/get_subscription_pos";
    const endpointCards = "configuration/get_card";
    const endpointCreateCard = "configuration/card_customer"
    const [t] = useTranslation('global');
    const handlerApp = HandlerApp();
    const { register, handleSubmit, formState: { errors }, clearErrors: clearErrorsCard, setValue, control,reset } = useForm()
    const { register: registerLoc, handleSubmit: handleSubmitLoc, clearErrors, formState: { errors: errosLoc }, setValue: setValueLoc, control: controlLoc } = useForm()
    const api = useFetch();
    const nav = useNavigate();
    const { id } = useParams();
    const [dataUser, setDataUser] = useState([]);
    const [dataSubscription, setDataSubscription] = useState([]);
    const [dataPos, setDataPos] = useState([]);
    const [dataCards, setDataCards] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [editCard, setEditCard] = useState(false);
    const { app, modeTheme } = useContext(AppContext);
    const [selectedCard, setSelectedCard] = useState(false);
    const [locationsName, setLocationsName] = useState([{ code: '', name: '' }]);
    const [locationsPos, setLocationsPos] = useState([{ code: '', name: '' }]);
    const [subscriptionName, setSubscriptionName] = useState();
    const [subscriptionPos, setSubscriptionPos] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalPos, setModalPos] = useState(false);
    const [showErrorCards, setShowErrorCards] = useState(false);
    const [subscriptionError, setSubscriptionError] = useState('');
    const [subLocationData, setSubLocationData] = useState({
        subscription_id: '',
        location_id: '',
        coupon_id: ''

    });

    const [subPosData, setSubPosData] = useState({
        subscription_id: '',
        location_id: '',
        quantity: '',
        coupon_id: ''

    });

    const [cardForm, setCardForm] = useState({
        card_number: "",
        name: "",
        card_expiry: "",
        card_securitycode: "",
        card_zipcode: "",
        person_email: "",
        default: selectedCard
    });

    const columnsSub = [
        {
            id: 'location_name',
            name: t('locationComponent.location'),
            selector: row => row.location_name,
        },
        {
            id: 'subscriptionlocation_start_date',
            name: t('myAccounts.dateStart'),
            selector: row => moment(row.subscriptionlocation_start_date).format("MMM, DD [de] YYYY"),
        },
        {
            id: 'subscriptionlocation_end_date',
            name: t('myAccounts.date'),
            selector: row => moment(row.subscriptionlocation_end_date).format("MMM, DD [de] YYYY"),
        },
        {
            id: 'subscriptionlocation_auto_renewal',
            name: t('myAccounts.autorenew'),
            selector: row => row.subscriptionlocation_auto_renewal === 1 ? t('myAccounts.active') : t('myAccounts.inactive'),
        },
        {
            id: 'subscription_price',
            name: t('myAccounts.value'),
            selector: row => row.subscription_type === "LOCATION FREE" ? t('myAccounts.free') : handlerApp.formatCurrency(row.subscription_price),
        },
        {
            id: 'action',
            name: t('action'),
            selector: row => {
                if (row.subscriptionlocation_state === 'INACTIVE') {
                    // Si el estado es INACTIVE, mostrar directamente que la suscripción está inactiva
                    return <span>{t('myAccounts.subInactive')}</span>;
                } else if (row.subscriptionlocation_state === 'ACTIVE') {
                    // Si el estado es ACTIVE, continuar con las validaciones previas
                    if (row.subscriptionlocation_auto_renewal === 1 && moment(row.subscriptionlocation_end_date).isAfter(moment())) {
                        // Suscripción activa con auto-renovación
                        return (
                            <button
                                className="cancel-subscription-button"
                                onClick={() => cancelSub(row)}
                            >
                                {t('myAccounts.cancelSub')}
                            </button>
                        );
                    } else if (row.subscriptionlocation_auto_renewal === 0) {
                        // Suscripción activa, pero sin auto-renovación
                        if (row.is_latest_subscription ==="YES") {
                            return (
                                <button
                                    className="cancel-subscription-button"
                                    onClick={() => activeSub(row)}
                                >
                                    {t('myAccounts.activateSub')}
                                </button>
                            );
                            
                        }else{
                            return <span>{t('myAccounts.subActive')}</span>;
                        }
                    } else if (row.subscriptionlocation_auto_renewal === 1 && moment(row.subscriptionlocation_end_date).isSameOrBefore(moment(), 'day')) {
                        // Intentar el cobro durante 5 días después de la fecha de finalización
                        let endDatePlusFive = moment(row.subscriptionlocation_end_date).add(5, 'days');
                        if (moment().isAfter(endDatePlusFive, 'day')) {
                            // Si después de 5 días no se ha realizado el cobro, marcar como inactiva
                            return <span>{t('myAccounts.subInactive')}</span>;
                        } else {
                            // Aún dentro del periodo de 5 días de intento de cobro
                            return <span>{t('myAccounts.subPendingPayment')}</span>;
                        }
                    } else if (row.subscription_type === 'LOCATION FREE') {
                        // Suscripción gratuita
                        return <span>{t('myAccounts.freeSubscription')}</span>;
                    } else {
                        // Caso predeterminado si no se cumple ninguna condición anterior
                        return <span>{t('myAccounts.subInactive')}</span>;
                    }
                } else {
                    // Caso predeterminado: mostrar inactiva si el estado no es ACTIVE ni INACTIVE
                    return <span>{t('myAccounts.subInactive')}</span>;
                }
            },
            width: "150px",
            button: true.toString(),
        }
    ];

    const columnsPos = [
        {
            id: 'location_name',
            name: t('locationComponent.location'),
            selector: row => row.location_name,
            width: "100px"
        },
        {
            id: 'subscriptionpos_start_date',
            name: t('myAccounts.dateStart'),
            selector: row => moment(row.subscriptionpos_start_date).format("MMM, DD [de] YYYY"),
        },
        {
            id: 'subscriptionpos_end_date',
            name: t('myAccounts.date'),
            selector: row => moment(row.subscriptionpos_end_date).format("MMM, DD [de] YYYY"),
        },
        {
            id: 'value',
            name: t('myAccounts.value'),
            selector: (row, index) => {
                if (row.subscription_type === "POS FREE") { return t('myAccounts.free') } else {
                    return handlerApp.formatCurrency(
                        row.subscription_price
                    )
                }
            },
            width: "90px"
        },
        {
            id: 'subscriptionpos_serial',
            name: t('myAccounts.code'),
            selector: row => row.subscriptionpos_serial,
        },
        {
            id: 'subscriptionpos_auto_renewal',
            name: t('myAccounts.autorenew'),
            selector: row => row.subscriptionpos_auto_renewal === 1 ? t('myAccounts.active') : t('myAccounts.inactive'),
            width: "130px",
        },
        {
            id: 'pos_serial',
            name: t('myAccounts.serial'),
            selector: row => row.pos_serial !== null ? row.pos_serial : 'N/A',
        },
        {
            id: 'action',
            name: t('action'),
            selector: row => {
                if (row.subscriptionpos_state === 'INACTIVE') {
                    // Si el estado es INACTIVO, mostrar directamente que la suscripción está inactiva
                    return <span>{t('myAccounts.subInactive')}</span>;
                } else if (row.subscriptionpos_state === 'ACTIVE') {
                    if (row.subscription_type === 'POS FREE') {
                        // Suscripción gratuita
                        return <span>{t('myAccounts.freeSubscription')}</span>;
                    }
                    // Si el estado es ACTIVO, validar las condiciones de renovación y fecha
                    else if (row.subscriptionpos_auto_renewal === 1 && moment(row.subscriptionpos_end_date).isAfter(moment())) {
                        // Suscripción activa con auto-renovación
                        return (
                            <button
                                className="cancel-subscription-button"
                                onClick={() => cancelSubPos(row)}
                            >
                                {t('myAccounts.cancelSub')}
                            </button>
                        );
                    } else if (row.subscriptionpos_auto_renewal === 0) {
                        // Suscripción activa, pero sin auto-renovación
                        return (
                            <button
                                className="cancel-subscription-button"
                                onClick={() => activeSubPos(row)}
                            >
                                {t('myAccounts.activateSub')}
                            </button>
                        );
                    } else if (row.subscriptionpos_auto_renewal === 1 && moment(row.subscriptionpos_end_date).isSameOrBefore(moment(), 'day')) {
                        // Intentar el cobro durante 5 días después de la fecha de finalización
                        let endDatePlusFive = moment(row.subscriptionpos_end_date).add(5, 'days');
                        if (moment().isAfter(endDatePlusFive, 'day')) {
                            // Si después de 5 días no se ha realizado el cobro, marcar como inactiva
                            return <span>{t('myAccounts.subInactive')}</span>;
                        } else {
                            // Aún dentro del periodo de 5 días de intento de cobro
                            return <span>{t('myAccounts.subPendingPayment')}</span>;
                        }
                    } else {
                        // Caso predeterminado si no se cumple ninguna condición anterior
                        return <span>{t('myAccounts.subInactive')}</span>;
                    }
                } else {
                    // Caso predeterminado: mostrar inactiva si el estado no es ACTIVE ni INACTIVE
                    return <span>{t('myAccounts.subInactive')}</span>;
                }
            },
            width: "150px",
            button: true.toString(),
        },
    ];

    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                if (!hasValidLocations()) {
                    showNoSubscriptionAlert();
                } else {
                    setModalIsOpen(true);
                }
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                break;
        }
    }

    const hasValidLocations = () => {
        return locationsName.some(location => location.code && location.name);
    };

    const showNoSubscriptionAlert = () => {
        Swal.fire({
            title: t('myAccounts.noLocationsAvailable'),
            text: t('myAccounts.noLocationsAvailableMessage'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: "#08a215",
            cancelButtonColor: "#636c74",
            confirmButtonText: t('myAccounts.createLocation'),
            cancelButtonText: t('myAccounts.close')
        }).then((result) => {
            if (result.isConfirmed) {
                nav("/administration/locations");
            }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSubLocationData({ ...subLocationData, [name]: value });
    };

    const handleInputChangePos = (e) => {
        const { name, value } = e.target;
        setSubPosData({ ...subPosData, [name]: value });
    };

    const handleSubmitSubLocation = (e) => {
        handlerApp.setLoader(true);
        api.post(endpoint, subLocationData)
            .then(response => {
                if (response.success) {

                    setModalIsOpen(false)
                    setSubLocationData({
                        subscription_id: '',
                        location_id: '',
                        quantity: 1,
                        coupon_id: ''
                    })
                    handlerApp.showOk(response);
                    getSubscriptionData();
                    getSubscriptionPosData();
                    getLocationsWithoutSubLocation();
                } else {
                    if (response.hasOwnProperty('data')) {
                        handlerApp.showErrorWithData(response.message, response.data);
                    } else {
                        handlerApp.handlerResponse(response);
                    }
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
            })
    };

    const handleSubmitSubPos = (e) => {
        handlerApp.setLoader(true);
        api.post(endpointPos, subPosData)
            .then(response => {
                if (response.success) {
                    setModalIsOpen(false)
                    setSubPosData({
                        subscription_id: '',
                        location_id: '',
                        quantity: '',
                        coupon_id: ''
                    })
                    handlerApp.showOk(response);
                    getLocationsWithoutSubLocation();
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
                getSubscriptionPosData();
                getSubscriptionData();
            })
            .catch(error => {
                handlerApp.showError(error);
                handlerApp.setLoader(false);
            })
    };

    const handleCheckboxChange = (cardId) => {
        handlerApp.setLoader(true);
        api.get(endpointCardDefault + '/' + cardId)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    getCustomerCard()
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    };

    const getUserData = () => {
        handlerApp.setLoader(true);
        api.get(endpointUser + "/" + id)
            .then(response => {
                if (response.success) {
                    setDataUser(response.data[0]);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(error);
                handlerApp.setLoader(false);
            })
    }

    const getLocationsWithoutSubLocation = () => {
        setLocationsName([])
        handlerApp.setLoader(true);
        api.get(endpoint + '/get_locations')
            .then(response => {
                if (response.success) {
                    setLocationsName(response.data.map((loc) => { return { code: loc.location_id, name: loc.location_name } }))
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const getSubscriptionData = () => {
        handlerApp.setLoader(true);
        api.get(endpointSub)
            .then(response => {
                if (response.success) {
                    setDataSubscription(response.data);

                    let locations = response.data
                        .filter(sub => sub.subscriptionlocation_active === 1)
                        .map(loc => ({ code: loc.location_id, name: loc.location_name }));

                    // Filtrar duplicados utilizando un Set para los códigos de location
                    let uniqueLocations = Array.from(new Set(locations.map(loc => loc.code)))
                        .map(code => {
                            return locations.find(loc => loc.code === code);
                        });

                    setLocationsPos(uniqueLocations);
                }

                handlerApp.setLoader(false);
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            });
    };


    const getSubByType = () => {
        handlerApp.setLoader(true);
        api.get('/configuration/get_subscription_type')
            .then(response => {
                if (response.success) {
                    let subLocationType = response.data.find((sub) => {
                        return sub.subscription_type === 'LOCATION'
                    })
                    let subLocationTypePos = response.data.find((sub) => {
                        return sub.subscription_type === 'POS'
                    })
                    setSubscriptionName(subLocationType)
                    setSubscriptionPos(subLocationTypePos)
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const getSubscriptionPosData = () => {
        handlerApp.setLoader(true);
        api.get(endpointSubPos)
            .then(response => {
                if (response.success) {
                    setDataPos(response.data)
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const getCustomerCard = () => {
        handlerApp.setLoader(true);
        setDataCards([])
        setShowErrorCards(false)
        api.get(endpointCards)
            .then(response => {
                if (response.success) {
                    setDataCards(response.data[0]);
                    if(response.data.length == 0){
                        setShowErrorCards(true)
                    }
                    handlerApp.setLoader(false);
                } else {
                    setShowErrorCards(true)
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(error);
                handlerApp.setLoader(false);
            })
    }

    const handleChangeData = (e) => {
        let key = e.target.name;
        let value = e.target.value;

        setCardForm({
            ...cardForm,
            [key]: value
        });
    }

    const handleNavigate = () => {
        nav('/configuration/profile/' + id + '/edit')
    }

    const cancelSub = (sub) => {
        Swal.fire({
            title: t('myAccounts.subCancel'),
            text: t('myAccounts.subInm'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#be2a2a",
            cancelButtonColor: "#636c74",
            confirmButtonText: t('myAccounts.confirm'),
            cancelButtonText: t('cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                api.put('/subscription-location-cancel/' + sub.subscriptionlocation_id)
                    .then(response => {
                        if (response.success) {
                            Swal.fire({
                                title: t('S002'),
                                text: `${t('myAccounts.subInc2')}${handlerApp.getDate(sub.subscriptionlocation_end_date)}`,
                                icon: "success",
                                confirmButtonColor: "#08a215",
                                confirmButtonText: t('transactionsComponent.continue')
                            });
                            getLocationsWithoutSubLocation()
                            getSubscriptionPosData();
                            getSubscriptionData();
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                        handlerApp.setLoader(false);
                    })
                    .catch(error => {
                        handlerApp.showError();
                        handlerApp.setLoader(false);
                    })
            }
        });
    }

    const activeSub = (sub) => {
        Swal.fire({
            title: t('myAccounts.subActivate'),
            text: t('myAccounts.subAutoRenew'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#08a215",
            cancelButtonColor: "#636c74",
            confirmButtonText: t('myAccounts.confirm'),
            cancelButtonText: t('cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                api.put('/subscription-location-active/' + sub.subscriptionlocation_id)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            getLocationsWithoutSubLocation()
                            getSubscriptionPosData();
                            getSubscriptionData();
                        }
                        handlerApp.setLoader(false);
                    })
                    .catch(error => {
                        handlerApp.showError();
                        handlerApp.setLoader(false);
                    })
            }
        });
    }

    const cancelSubPos = (sub) => {
        Swal.fire({
            title: t('myAccounts.subCancel'),
            text: t('myAccounts.subInmPos'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#be2a2a",
            cancelButtonColor: "#636c74",
            confirmButtonText: t('myAccounts.confirm'),
            cancelButtonText: t('cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                api.put('/subscription-pos-cancel/' + sub.subscriptionpos_id)
                    .then(response => {
                        if (response.success) {
                            Swal.fire({
                                title: t('S002'),
                                text: `${t('myAccounts.subInc2')}${handlerApp.getDate(sub.subscriptionpos_end_date)}`,
                                icon: "success",
                                confirmButtonColor: "#08a215",
                                confirmButtonText: t('transactionsComponent.continue')
                            });
                            getSubscriptionPosData();
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                        handlerApp.setLoader(false);
                    })
                    .catch(error => {
                        handlerApp.showError();
                        handlerApp.setLoader(false);
                    })
            }
        });
    }

    const activeSubPos = (sub) => {
        Swal.fire({
            title: t('myAccounts.subActivate'),
            text: t('myAccounts.subAutoRenew'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#08a215",
            cancelButtonColor: "#636c74",
            confirmButtonText: t('myAccounts.confirm'),
            cancelButtonText: t('cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                api.put('/subscription-pos-active/' + sub.subscriptionpos_id)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            getSubscriptionPosData();
                        }
                        handlerApp.setLoader(false);
                    })
                    .catch(error => {
                        handlerApp.showError();
                        handlerApp.setLoader(false);
                    })
            }
        });
    }

    const renewSubLoc = (sub) => {
        Swal.fire({
            title: t('myAccounts.subRenew'),
            text: t('myAccounts.subInc'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#08a215",
            cancelButtonColor: "#be2a2a",
            confirmButtonText: t('myAccounts.confirm'),
            cancelButtonText: t('cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                api.get('renew-subscription-location/' + sub.subscriptionlocation_id)
                    .then(response => {
                        if (response.success) {
                            getSubscriptionPosData();
                            getSubscriptionData();
                            handlerApp.showOk(response);
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                        handlerApp.setLoader(false);
                    })
                    .catch(error => {
                        handlerApp.showError();
                        handlerApp.setLoader(false);
                    })
            }
        });
    }

    const renewSubPos = (sub) => {
        Swal.fire({
            title: t('myAccounts.subRenew'),
            text: t('myAccounts.subInc'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#08a215",
            cancelButtonColor: "#636c74",
            confirmButtonText: t('myAccounts.confirm'),
            cancelButtonText: t('cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                api.get('renew-subscription-pos/' + sub.subscriptionpos_id)
                    .then(response => {
                        if (response.success) {
                            getSubscriptionPosData();
                            getSubscriptionData();
                            handlerApp.showOk(response);
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                        handlerApp.setLoader(false);
                    })
                    .catch(error => {
                        handlerApp.showError();
                        handlerApp.setLoader(false);
                    })
            }
        });
    }

    const handleFormCard = () => {
        handlerApp.setLoader(true);
        let cardNumber = cardForm.card_number.replace(/\s/g, "")
        let cardExpiry = cardForm.card_expiry.replace('/', '');
        cardForm.card_number = cardNumber
        cardForm.card_expiry = cardExpiry
        cardForm.person_email = dataUser.person_email
        cardForm.default = selectedCard === true ? 1 : 0;
        api.post(endpointCreateCard, cardForm)
            .then(response => {
                if (response.success) {
                    setEditCard(false)
                    setCardForm({
                        card_number: "",
                        name: "",
                        card_expiry: "",
                        card_securitycode: "",
                        card_zipcode: "",
                        person_email: "",
                        default: selectedCard
                    })
                    getCustomerCard()
                } else {
                    handlerApp.handlerResponse(response)
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(error);
                handlerApp.setLoader(false);
            })
    }

    const handleCancelModal = () => {
        setModalPos(false);
        setSubLocationData({
            subscription_id: '',
            location_id: '',
            quantity: 1,
            coupon_id: ''
        });
        setSubPosData({
            subscription_id: '',
            location_id: '',
            quantity: '',
            coupon_id: ''
        });
        clearErrors();
        clearErrorsCard()
        setSubscriptionError('');
        setModalIsOpen(false);
    }

    const handleCancelModalCard = () => {
        setEditCard(false)
        setCardForm({
            card_number: "",
            name: "",
            card_expiry: "",
            card_securitycode: "",
            card_zipcode: "",
            person_email: "",
            default: selectedCard
        })
        reset()
    }

    function handleDeleteCard(id) {
        Swal.fire({
            title: t('myAccounts.cardDelete'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#646464",
            cancelButtonColor: "#181D32",
            confirmButtonText: t('myAccounts.confirm'),
            cancelButtonText: t('cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                api.deleteMethod(endpointCreateCard + '/' + id)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            getCustomerCard()
                            handlerApp.setLoader(false);
                        } else {
                            handlerApp.handlerResponse(response);
                            handlerApp.setLoader(false);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                        handlerApp.setLoader(false);
                    })
            }
        });

    }

    const handleChangeCardDefault = (bool) => {
        setSelectedCard(bool)
    }

    const handleSubmitWithValidation = (data) => {
        // Verifica si hay una suscripción seleccionada
        const subscriptionId = modalPos ? subPosData.subscription_id : subLocationData.subscription_id;

        if (!subscriptionId) {
            setSubscriptionError(t('validationErrorMessages.subscriptionRequired'));
            return;
        }

        setSubscriptionError('');

        if (modalPos) {
            handleSubmitSubPos(data);
        } else {
            const { quantity, ...dataWithoutQuantity } = data;

            handleSubmitSubLocation(dataWithoutQuantity);
        }
    };

    useEffect(() => {
        getUserData()
        getCustomerCard()
        getLocationsWithoutSubLocation()
        getSubByType()
        getSubscriptionData()
        getSubscriptionPosData()
    }, [])

    useEffect(() => {
        setSelectedCard(dataCards.find(card => card.customercard_default === 1)?.customercard_id)
    }, [dataCards])

    useEffect(() => {
        setPermissions(app.permissions[endpointUser])
    }, [app])

    const navigateMonthBills = () => {
        nav('/transactions-customer-month')
    }

    return (
        <div className="app-without-margin">
            <div className="row d-flex justify-content-center">
                <div className="col-11 col-lgx-7 col-md-5 section-forms row d-flex justify-content-center ">
                    <div className="col-md-5 d-flex flex-column justify-content-center">
                        {(dataUser.person_image === "" || dataUser.person_image === undefined || dataUser.person_image === null) &&
                            <div className="col-md-12 d-flex mb-3 justify-content-center">
                                <section className="section-image-view circle" style={{ backgroundImage: `url('${imageNotFound}')` }}>
                                </section>
                            </div>
                        }
                        {dataUser.person_image !== "" && dataUser.person_image !== undefined && dataUser.person_image !== null &&
                            <div className="col-md-12 d-flex mb-3 justify-content-center">
                                <section className="section-image-view circle" style={{ backgroundImage: `url('${dataUser.person_image}')` }}>
                                </section>
                            </div>
                        }
                    </div>
                    <div className="col-11 col-lgx-7 col-md-5 d-flex flex-column justify-content-center user-profile">
                        <section className="col-12 align-end">
                            <button className="bg-transparent border-0">
                                <span className="material-symbols-outlined" onClick={handleNavigate}>edit</span>
                            </button>
                        </section>
                        <div className="col-md-12 mt-3">
                            <p className="bold big-text overflow-text mb-4">{dataUser.person_firstname + ' ' + dataUser.person_surname}</p>
                            <div className="col-md-12 mt-3 d-flex">
                                <span className="material-symbols-outlined">person</span>
                                <p className="regular medium-text overflow-text">{dataUser.user_username}</p>
                            </div>
                            <div className="col-md-12 d-flex">
                                <span className="material-symbols-outlined">call</span>
                                <p className="regular medium-text overflow-text">{dataUser.person_phone}</p>
                            </div>
                            {modeTheme ?
                                permissions?.indexOf("mothn") !== -1 && (
                                    <button className="dark-boton-facturas mb-2 d-flex" onClick={navigateMonthBills}>
                                        <span className="material-symbols-outlined me-2">receipt_long</span>
                                        <p className="p-0 m-0">{t('mothnBills.myBills')}</p>
                                    </button>
                                )
                                :
                                permissions?.indexOf("mothn") !== -1 && (
                                    <button className="boton-facturas mb-2 d-flex" onClick={navigateMonthBills}>
                                        <span className="material-symbols-outlined me-2">receipt_long</span>
                                        <p className="p-0 m-0">{t('mothnBills.myBills')}</p>
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lgx-7 col-sm-11 section-forms">
                    <div className="col-md-12 d-flex justify-content-between">
                        <h2 className="title-section-sub">{t('myAccounts.cards')}</h2>
                        {permissions?.indexOf("create") !== -1 && (
                            <button className="btn-table-add col-1" onClick={() => { setEditCard(!editCard) }}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                        )}
                    </div>

                    {dataCards.length > 0 ? dataCards.map((card, index) => {
                        return (
                            <div className="col-md-12 d-flex mb-1 align-items-center card-content" key={index}>
                                <div className="col-8 d-flex">
                                    {card.customercard_brand == 'VISA' ?
                                        <img className="bold-image ms-1 me-2" src={Visa} alt={'VISA'} />
                                        : card.customercard_brand == 'AMEX' ?
                                            <img className="bold-image-table" src={Amex} alt={'AMEX'} />
                                            : card.customercard_brand == 'MC' ?
                                                <img className="bold-image" src={MC} alt={'MC'} />
                                                : card.customercard_brand == 'DINERS_CLUB' &&
                                                <img className="bold-image-diners" src={Diners} alt={'DINERS_CLUB'} />
                                    }
                                    <section className="col-10 d-flex row py-2 card-numbers-content">
                                        <div className="row row-card-numbers">
                                            <span className="card-numbers col-lg-8">**** **** **** {card.card_last4}</span>
                                            <span className="card-numbers col-lg-4">{card.card_exp_month} / {card.card_exp_year}</span>

                                        </div>
                                        <span className="card-numbers">{card.customercard_card_holder}</span>
                                    </section>

                                </div>
                                <div className="col-4 d-flex align-items-center justify-content-center">
                                    {modeTheme ?
                                        (selectedCard === card.customercard_id) ?
                                            <p className="label-default-dark mt-3">{t('myAccounts.default')}</p>
                                            :
                                            <button className="default-card-button-dark" title="Default" onClick={() => handleCheckboxChange(card.customercard_id)}>
                                                <span className="color-text-default-dark">{t('myAccounts.setDefault')}</span>
                                            </button>
                                        :
                                        (selectedCard === card.customercard_id) ?
                                            <p className="label-default mt-3">{t('myAccounts.default')}</p>
                                            :
                                            <button className="default-card-button" title="Default" onClick={() => handleCheckboxChange(card.customercard_id)}>
                                                <span className="color-text-default">{t('myAccounts.setDefault')}</span>
                                            </button>}
                                    {dataCards.length > 1 && 
                                        <button className="btn-table-actions mt-2 text-align-right pe-2" title="delete" onClick={() => handleDeleteCard(card.customercard_id)}>
                                            <span className="material-symbols-outlined">delete</span>
                                        </button>
                                    }
                                </div>
                            </div>
                        )
                    }): showErrorCards &&<p style={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }}>{t('M106')}</p>}
                    {dataCards.length > 0 && !selectedCard &&
                        <p style={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }}>{t('E215')}</p>
                    }
                    <Modal
                        isOpen={editCard}
                        onRequestClose={() => setEditCard(false)}
                        className="col-md-12 card-content-modal swal2-popup"
                        style={{
                            overlay: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)'
                            },
                        }}>
                        <form onSubmit={handleSubmit(handleFormCard)} noValidate className='col-12 d-flex flex-column align-items-center justify-content-center py-4 px-4'>
                            <h2 className="title-section-sub mt-2">{t('myAccounts.addCard')}</h2>
                            <div className="col-11">
                                <TextField name="card_number" label={t('userRegister.cardNumber')}
                                    value={cardForm.card_number}
                                    {...register("card_number", { required: true })}
                                    error={errors.card_number != null}
                                    helperText={
                                        errors.card_number?.type === 'required'
                                            ? t('validationErrorMessages.required') : ""
                                    }
                                    onChange={handleChangeData}
                                    InputProps={{
                                        inputComponent: InputMask,
                                        inputProps: {
                                            mask: "9999 9999 9999 9999",
                                            maskChar: "",
                                        },
                                    }}
                                    required variant="standard" size="small"
                                />
                            </div>
                            <div className="col-11">
                                <TextField name="name" label={t('userRegister.cardName')}
                                    value={cardForm.name}
                                    {...register("name", { required: true })}
                                    error={errors.name != null}
                                    helperText={
                                        errors.name?.type === 'required'
                                            ? t('validationErrorMessages.required') : ""
                                    }
                                    onChange={handleChangeData}
                                    required variant="standard" size="small"
                                />
                            </div>
                            <div className="col-11 row d-flex justify-content-between">
                                <div className="col-6 px-0">
                                    <TextField
                                        name="card_expiry"
                                        label={t('userRegister.expirationDate') + " (MMYY)"}
                                        value={cardForm.card_expiry}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CalendarMonthIcon />
                                                </InputAdornment>
                                            ),
                                            maxLength: 4,
                                            inputComponent: InputMask,
                                            inputProps: {
                                                mask: "99/99",
                                                maskChar: "",
                                            }
                                        }}
                                        {...register("card_expiry", { required: true })}
                                        error={errors.card_expiry != null}
                                        helperText={
                                            errors.card_expiry?.type === 'required'
                                                ? t('validationErrorMessages.required') : ""
                                        }
                                        onChange={handleChangeData}
                                        required
                                        variant="standard"
                                        size="small"
                                    />
                                </div>
                                <div className="col-5 px-0">
                                    <TextField name="card_securitycode" label="CVV / CVC"
                                        {...register("card_securitycode", { required: true })}
                                        error={errors.card_securitycode != null}
                                        helperText={
                                            errors.card_securitycode?.type === 'required'
                                                ? t('validationErrorMessages.required') : ""
                                        }
                                        value={cardForm.card_securitycode}
                                        autoComplete="off"
                                        onChange={handleChangeData}
                                        type="password"
                                        required variant="standard" size="small"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CreditCardIcon />
                                                </InputAdornment>
                                            ),
                                            inputProps: {
                                                maxLength: 3,
                                            },
                                        }} />
                                </div>
                            </div>
                            <div className="col-11">
                                <TextField name="card_zipcode" label={t('zipCode')}
                                    value={cardForm.card_zipcode}
                                    {...register("card_zipcode", { required: true })}
                                    error={errors.card_zipcode != null}
                                    helperText={
                                        errors.card_zipcode?.type === 'required'
                                            ? t('validationErrorMessages.required') : ""
                                    }
                                    onChange={handleChangeData}
                                    required variant="standard" size="medium"
                                />
                            </div>
                            <div className="d-flex align-items-center align-self-start px-4">
                                <input className="me-2" type="checkbox" onChange={() => handleChangeCardDefault(true)} />
                                <label>{t('myAccounts.defaultCard')}</label>
                            </div>
                            <center className="col-12 d-flex gap-3 justify-content-center mt-5">
                                <Button className="col-4 swal2-styled swal2-confirm" type="submit">{t('myAccounts.save')}</Button>
                                <Button className="col-4 swal2-styled swal2-cancel" onClick={() => handleCancelModalCard()} >{t('cancel')}</Button>
                            </center>
                        </form>
                    </Modal>
                </div>

            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-md-11 col-lgx-7 col-sm-11 col-xs-6 section-forms">
                    <div className="col-md-12 row d-flex justify-content-between">
                        <h2 className="title-section-sub col-10">{t('myAccounts.subscription')}</h2>
                        {permissions?.indexOf("create") !== -1 && (
                            <button className="btn-table-add col-1" onClick={() => { handlerAction(null, 'create') }}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                        )}
                    </div>
                    <DataTable
                        columns={columnsSub}
                        data={dataSubscription}
                        noDataComponent={<NoDataTables />}
                        theme={modeTheme ? "solarized" : ""}
                        pagination
                    />
                </div>
                <div className="col-md-11 col-lgx-7 col-sm-11 section-forms">
                    <div className="col-md-12 row d-flex justify-content-between">
                        <h2 className="title-section-sub col-6">{t('myAccounts.subscriptionPos')}</h2>
                        {permissions?.indexOf("create") !== -1 && (
                            <button className="btn-table-add col-1" onClick={() => { setModalPos(true); setModalIsOpen(true) }}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                        )}
                    </div>
                    <DataTable
                        columns={columnsPos}
                        data={dataPos}
                        noDataComponent={<NoDataTables />}
                        theme={modeTheme ? "solarized" : ""}
                        pagination
                    />

                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className="modal-sub-location d-flex justify-content-center align-items-center"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                }}
                shouldCloseOnOverlayClick={false} // Evita el cierre al hacer clic fuera del modal
                shouldCloseOnEsc={false} // Evita el cierre al presionar la tecla "Esc"
            >
                <form className="d-flex flex-column justify-content-center align-items-center p-3 cmodal" onSubmit={handleSubmitLoc(handleSubmitWithValidation)}>
                    <div className="title-section-forms mb-3">
                        {modalPos ? t('myAccounts.createSubPos') : t('myAccounts.createSubLocation')}
                    </div>
                    <div className="mx-5 d-flex-modal gap-3 col-12">
                        <BoldSelectValidated
                            title={t('myAccounts.location') + " *"}
                            value={modalPos ? subPosData.location_id : subLocationData.location_id}
                            name="location_id"
                            options={modalPos ? locationsPos : locationsName}
                            onChange={modalPos ? handleInputChangePos : handleInputChange}
                            register={registerLoc}
                            errors={errosLoc}
                            required={true}
                        />
                        {modalPos &&
                            <TextField
                                name="quantity"
                                label={t('myAccounts.quantity')}
                                value={subPosData.quantity}
                                {...registerLoc("quantity", { required: true })}
                                onChange={handleInputChangePos}
                                error={!!errosLoc.quantity}
                                variant="standard"
                                size="small"
                                type="number"
                                helperText={
                                    (errors.quantity?.type === 'required' || errosLoc.quantity?.type === 'required')
                                        ? t('validationErrorMessages.required')
                                        : ""
                                }
                            />
                        }
                        <TextField name="coupon_id" label={t('myAccounts.coupon') + " (" + t('optional') + ")"}
                            value={modalPos ? subPosData.coupon_id : subLocationData.coupon_id}
                            register={true}
                            error={errosLoc.name != null}
                            onChange={modalPos ? handleInputChangePos : handleInputChange}
                            variant="standard" size="small"

                        />
                    </div>
                    {modeTheme ?
                        <div className="col-7 plan-card mt-3 py-4 px-3" style={{ backgroundColor: (subLocationData.subscription_id !== '' || subPosData.subscription_id !== '') ? '#36366E' : '#5a5f72', color: (subLocationData.subscription_id !== '' || subPosData.subscription_id !== '') ? 'white' : 'white' }} >
                            <p className="title-section-sub medium-text regular mb-0" style={{ color: (subLocationData.subscription_id !== '' || subPosData.subscription_id !== '') ? 'white' : 'white' }}>{modalPos ? subscriptionPos && subscriptionPos.subscription_name : subscriptionName && subscriptionName.subscription_name}</p>
                            <div className="d-flex align-items-center">
                                <p className="bold" style={{ fontSize: '30px' }}>{modalPos ? subscriptionPos && handlerApp.formatCurrency(subscriptionPos.subscription_price) : subscriptionName && handlerApp.formatCurrency(subscriptionName.subscription_price)}</p>
                                <p className="light medium-text ms-2">{modalPos ? subscriptionPos && t('myAccounts.for') + ' ' + subscriptionPos.subscription_life + ' ' + t('myAccounts.days') : subscriptionName && t('myAccounts.for') + ' ' + subscriptionName.subscription_life + ' ' + t('myAccounts.days')}</p>
                            </div>
                            <center className="col-12">
                                <Button className="col-10 btn-gray-light" onClick={() => { modalPos ? setSubPosData({ ...subPosData, subscription_id: subscriptionPos.subscription_id }) : setSubLocationData({ ...subLocationData, subscription_id: subscriptionName.subscription_id }) }}>{t('myAccounts.choose')}</Button>
                            </center>

                        </div> :
                        <div className="col-7 plan-card mt-3 py-4 px-3" style={{ backgroundColor: (subLocationData.subscription_id !== '' || subPosData.subscription_id !== '') ? '#202742' : 'white', color: (subLocationData.subscription_id !== '' || subPosData.subscription_id !== '') ? 'white' : '#202742' }} >
                            <p className="title-section-sub medium-text regular mb-0" style={{ color: (subLocationData.subscription_id !== '' || subPosData.subscription_id !== '') ? 'white' : '#202742' }}>{modalPos ? subscriptionPos && subscriptionPos.subscription_name : subscriptionName && subscriptionName.subscription_name}</p>
                            <div className="d-flex align-items-center">
                                <p className="bold" style={{ fontSize: '30px' }}>{modalPos ? subscriptionPos && handlerApp.formatCurrency(subscriptionPos.subscription_price) : subscriptionName && handlerApp.formatCurrency(subscriptionName.subscription_price)}</p>
                                <p className="light medium-text ms-2">{modalPos ? subscriptionPos && t('myAccounts.for') + ' ' + subscriptionPos.subscription_life + ' ' + t('myAccounts.days') : subscriptionName && t('myAccounts.for') + ' ' + subscriptionName.subscription_life + ' ' + t('myAccounts.days')}</p>
                            </div>
                            <center className="col-12">
                                <Button className="col-10 btn-gray-light" onClick={() => { modalPos ? setSubPosData({ ...subPosData, subscription_id: subscriptionPos.subscription_id }) : setSubLocationData({ ...subLocationData, subscription_id: subscriptionName.subscription_id }) }}>{t('myAccounts.choose')}</Button>
                            </center>
                        </div>}

                    {subscriptionError && <p style={{ color: 'red', fontSize: '13px' }}>{subscriptionError}</p>} {/* Mostrar mensaje de error */}

                    <center className="col-12 mt-2">
                        <Button className="col-5 btn-gray " onClick={handleCancelModal}>{t('cancel')}</Button>
                        <Button className="col-5 bold-btn-dark ms-3" type="submit">{t('myAccounts.pay')}</Button>
                    </center>
                </form>
            </Modal>
        </div>
    )
}

export default UserProfileView;